// jQuery(document).ready( function() {
document.addEventListener("DOMContentLoaded", function(){

// remove anchor from url when clicked
  // window.location.origin; = Returns base URL (https://example.com)
  // window.location.pathname; = Returns path only (/path/example.html)


  var skipLink = jQuery('.jumpLink');

    function removeHash() {
      history.replaceState('', document.title, window.location.origin + window.location.pathname + window.location.search);
    }

  skipLink.keyup(function(e) {
    var href = jQuery(this).attr('href');
    // e.preventDefault();
    setTimeout(function() {
      removeHash();
    }, 50);
  });

  skipLink.click(function(e) {
    var href = jQuery(this).attr('href');
    // e.preventDefault();
    setTimeout(function() {
      removeHash();
    }, 50);
  });
 

  // for testing: in console, if clicked, say "clicked"; if press enter, say "enter"
    // function handleEvent(e) {
    //   if(e.type == "click") {
    //     console.log("click"); //clicked
    //   }
    //   if(e.keyCode === 13) {
    //     console.log("enter"); //enter key pressed
    //   }
    // }


}); // ends document.ready
